.news-highlights-wrapper {
  width: 40%;
  margin: 1.5rem;
}
.news-highlights-header {
  color: #153b7d;
  font-weight: 600;
  margin-bottom: 2rem;
}
.news-highlights-date {
  color: #454242;
  display: block;
  font: 400 14px/1.4 sans-serif;
  letter-spacing: -0.1px;
}

.news-highlights-card {
  border-top: 0.5px solid #c0c0c075;
  padding: 13px;
}
.news-hightlights-links {
  display: block;
  font: 600 17px/1.56 sans-serif;
  margin: 10px 0 15px;
  text-decoration: none;
  color: #302f3c;
}

.news-highlights-summary {
  font: 400 14px/1.67 Georgia;
}

@media screen and (max-width: 1260px) {
  .news-highlights-container {
    width: 85%;
  }
}
