.proposals-table-wrapper {
  width: 60%;
  margin: 0 auto;
  margin-top: 4rem;
}
.proposals-table {
  border: 1px solid rgb(118 115 115 / 38%);
  line-height: 1.8;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.proposals-table thead tr {
  color: #153b7d;
  font-weight: 600;
}

.caret-icon {
  color: #3497fd;
  margin-left: 7px;
}

.proposals-name-column {
  text-underline-position: under;
  text-decoration: underline;
  color: #6f8841;
  cursor: pointer;
}

.proposals-name-column:hover {
  font-weight: 400;
  color: #153b7d;
  text-decoration-color: #153b7d;
}

.negative-number {
  color: red;
}

.breadcrumbs {
  margin-right: 10px;
}
.proposals-container {
  margin: 20px;
}

@media screen and (max-width: 1500px) {
  .proposals-table-wrapper {
    width: 100%;
    margin: 0 auto;
    margin-top: 4rem;
  }
}
