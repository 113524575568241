.upcoming-events-header {
  color: #153b7d;
  font-weight: 600;
  margin-bottom: 2rem;
}

.event {
  border-top: 0.5px solid #c0c0c075;
  padding: 5px;
}

.event-details-btn {
  color: #fff;
  background-color: #153b7dd6;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  outline: none;
  margin-bottom: 1rem;
  font-family: sans-serif;
  width: 100px;
  padding: 3px;
}
.events-date,
.event-details-title {
  font-weight: 600;
}

.events-date {
  color: #454242;
  display: block;
  font: 400 14px/1.4 sans-serif;
  letter-spacing: -0.1px;
}

.event-link {
  display: block;
  font: 600 17px/1.56 sans-serif;
  margin: 10px 0 15px;
  text-decoration: none;
  color: #302f3c;
}
.event-detail-wrapper {
  margin-top: 1rem;
}
.event-details-title {
  margin-top: 0.3rem;
}

@media screen and (max-width: 1260px) {
  .upcoming-events-wrapper {
    width: 85%;
  }
}
