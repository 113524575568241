.containerEl {
  border: 1px solid silver;
  background-color: rgb(249, 249, 249);
  padding-bottom: 2rem;
}

.x-axis path {
  d: path('M 0 1 V 0 H 930 V 1');
}
.y-axis path {
  d: path('M 1 3 H 0 V 440 H 1');
}
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
#dotprof {
  background-color: rgb(148, 103, 189);
}
#dotap {
  background-color: rgb(255, 127, 14);
}
#dotassistant {
  background-color: rgb(44, 160, 44);
}
#dotother {
  background-color: rgb(37, 68, 103);
}
.list {
  list-style-type: none;
}
.toolTipContainer {
  margin: 1rem;
}
.containerEl svg {
  height: 520px;
  width: 57%;
  padding: 2rem;
  padding-bottom: 0;
  padding-top: 0;
}
#gradient {
  background: linear-gradient(to right, #f7fbff, #08306b);
  width: 137px;
  height: 20px;
  border: 1px solid grey;
}
.legend-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  padding: 2rem;
  padding-bottom: 0;
}
.legend-subheader {
  font-weight: 500;
}
.gradient-container {
  margin-left: 18rem;
}
.gradient-legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.color-circle-legend {
  margin-left: 3rem;
}
.color-circle-legend ul {
  padding-left: 0px;
}
.color-circle-legend span {
  border-bottom: 1px solid;
  font-weight: 500;
}
.career-chart-header {
  display: flex;
  background: rgb(238, 238, 238);
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid silver;
}
.career-chart-dropdown {
  width: 200px;
  margin-left: 3.5rem;
}
.career-chart-header h4 {
  font-size: 1.25rem;
}
.select {
  width: 100%;
  background: rgb(113 126 140 / 82%);
  border-radius: 6px;
  padding: 6px;
  color: white;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.05rem;
}
@media screen and (min-width: 2500px) {
  .containerEl {
    padding-bottom: 2rem;
  }
  .containerEl svg {
    height: 650px;
    width: 60%;
    padding: 0;
    padding-left: 2rem;
  }
}
@media screen and (max-width: 1800px) {
  .containerEl svg {
    width: 68%;
  }
}
@media screen and (max-width: 1350px) {
  .containerEl svg {
    width: 95%;
  }
}

@media screen and (max-width: 1000px) {
  .containerEl svg {
    width: 95%;
    padding-left: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .color-circle-legend {
    margin-left: 0;
  }
  .gradient-container {
    margin-left: 8rem;
  }
  .containerEl svg {
    width: 100%;
    margin-top: -3rem;
    padding: 1rem;
  }
}
