.togglebtn {
  background-color: 'red';
}

.breadcrumbs {
  margin-right: 10px;
}

.container {
  margin: 20px;
}

.name {
  margin: 1rem 0 1rem 0;
}

.personInfo {
  display: flex;

  .infoColumn {
    display: flex;
    flex-direction: column;
    margin: 0 5rem 1rem 0;

    .infoColumnTitle {
      color: #153b7d;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

.table > thead:nth-child(1) {
  background-color: #153b7d;
  color: white;
}
