.projections-container {
  margin: 20px;
}
.projections-tabs-header {
  color: #153b7d;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
}

.projections-tabs-nav a {
  height: 40px;
}

.projections-tabs-nav {
  border: none;
  margin-bottom: 1px;
}
.conversions-tab-nav,
.proposal-totals-tab-nav {
  border: none;
  background-color: none;
}
#projections-tabs-nav-tab-conversions {
  margin-left: 18px;
}

#projections-tabs-nav-tab-core-proposal,
#projections-tabs-nav-tab-conversions {
  color: #153b7d;
  background-color: transparent;
  outline: none;
  font-weight: 600;
}
#projections-tabs-nav-tab-conversions:hover,
#projections-tabs-nav-tab-core-proposal:hover {
  color: #3497fd;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  border-bottom: none;
}
.nav-tabs .nav-link {
  border: none;
  border-bottom: none;
}
.breadcrumb-container {
  padding: 20px;
}
