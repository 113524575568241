.videos-wrapper {
  width: 52%;
  margin: 1.3rem;
}
.videos-header {
  color: #153b7d;
  font-weight: 600;
  margin-bottom: 2rem;
}
.videos-date {
  font-weight: 600;
}
.videos-summary {
  font: 400 14px/1.67 Georgia;
}
.video-card {
  max-width: 89%;
  display: flex;
  align-items: flex-start;
  height: 100%;
  border-top: 0.5px solid #c0c0c075;
  border-collapse: collapse;
}

.react-player {
  width: 63%;
  height: 100%;
  margin-top: 0px;
  height: 100%;
  padding: 10px;
  margin-right: 7px;
}

.video-description {
  width: 80%;
  padding: 10px;
}

.videos-links {
  font: 600 17px/1.56 sans-serif;
  text-decoration: none;
  color: #302f3c;
  margin-top: -35px;
  margin-top: 0px;
}
.video-cards-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (min-width: 1850px) {
  .video-card {
    max-width: 90%;
  }
}
@media screen and (max-width: 1820px) {
  .video-card {
    max-width: 100%;
  }
}
@media screen and (max-width: 1260px) {
  .videos-wrapper {
    width: 88%;
    margin-top: 2rem;
  }
  .news-highlights-wrapper {
    width: 88%;
  }
  .video-cards-container {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .video-card {
    max-width: 80%;
    flex-direction: column;
  }
  .video-description {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  .react-player {
    width: 90%;
  }
}
