.college-view {
  padding: 20px;
}

.header {
  color: #153b7d;
  font-size: 1.75rem;
  text-align: center;
}

.breadcrumbs {
  margin-right: 10px;
}

.college-units-accordion {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: start;
}

.college-units-list {
  width: 19%;
}

.college-accordion-container {
  width: 80%;
}
.card-header {
  display: flex;
  align-items: center;
}
.acr-proposals-header {
  margin: 0 2%;
  color: #153b7d;
}
.acr-projections-header {
  margin: 0 2%;
  color: #153b7d;
}

@media screen and (max-width: 1500px) {
  .card-body:first-child {
    width: 100%;
    margin: 0;
  }
  .college-proposals-table-wrapper {
    width: 95%;
  }
  .table-responsive {
    display: block;
    width: 99%;
    overflow-x: auto;
    margin: auto;
    overflow: auto;
    white-space: nowrap;
  }
  .tab-content {
    width: 100%;
  }
  #projections-tabs-nav-tabpane-conversions,
  #projections-tabs-nav-tabpane-core-proposal {
    width: 100%;
  }

  .conversions-wrapper .table-wrapper {
    width: 100%;
  }
  .proposal-totals-wrapper .table-wrapper {
    width: 100%;
  }
}
