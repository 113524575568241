.btn-primary {
  color: #fff;
  background-color: #153b7d;
  border-color: #153b7d;
}
.btn-primary:focus {
  background-color: #153b7d;
  outline: none;
  border-color: #153b7d;
}
.btn-primary:focus,
.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.card {
  margin: 0 auto;
  max-width: 1950px;
}

.acr-unit-proposals-header {
  margin: 0 2%;
  color: #153b7d;
}
.acr-unit-projections-header {
  margin: 0 2%;
  color: #153b7d;
}
