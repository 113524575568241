.conversions-wrapper,
.proposal-totals-wrapper {
  display: flex;
  width: 98%;
  flex-direction: row;
  margin: 0 auto;
}

.charts-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
}

.chart-conversions-ratios,
.chart-proposal-totals {
  position: relative;
  height: 23vh;
  width: 23vw;
  margin-top: 0;
  margin-left: 23px;
}

.table {
  border: 1px solid rgb(118 115 115 / 38%);
  line-height: 1.8;
}

.table td,
.table th {
  padding: 0.4rem;
  height: 55px;
}

.header-column {
  text-align: center;
}

input {
  outline: none;
}

.header-column {
  color: #153b7d;
  font-weight: 600;
}

.conversion-number-input {
  width: 95px;
}

.conversion-number-input,
.conversion-select-input {
  border: none;
  text-align: right;
  background: none;
}

.conversion-years-number-input {
  border: none;
  text-align: center;
  background: none;
  width: 80px;
  padding-left: 23px;
}

.proposal-number-input {
  width: 135px;
}

.proposal-number-input,
.proposal-select-input {
  border: none;
  text-align: center;
  background: none;
}

.conversion-select-input,
.proposal-select-input {
  padding-left: 0px;
  box-shadow: 0.5px 0.5px 2px grey;
  width: 70px;
  text-align: center;
  opacity: 1;
  color: #153b7d;
  font-weight: 600;
  border-radius: 1px;
}

.proposal-select-input::-webkit-inner-spin-button,
.conversion-select-input::-webkit-inner-spin-button {
  opacity: 1;
}

.increment-decrement-btn-wraper {
  text-align: center;
}

.number-cell-wraper {
  display: flex;
  justify-content: center;
}

.tbody-row:nth-child(1) td:nth-child(2),
.tbody-row:nth-child(4) td:nth-child(2),
.tbody-row:nth-child(7) td:nth-child(2) {
  text-align: end;
}

.tbody-row:nth-child(1) {
  border-top: 1px solid rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(4),
.tbody-row:nth-child(7) {
  border-top: 1px solid rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(2) td:nth-child(5) div input,
.tbody-row:nth-child(2) td:nth-child(6) div input,
.tbody-row:nth-child(2) td:nth-child(7) div input,
.tbody-row:nth-child(2) td:nth-child(8) div input {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}
.tbody-row:nth-child(2) td:nth-child(5) div span,
.tbody-row:nth-child(2) td:nth-child(6) div span,
.tbody-row:nth-child(2) td:nth-child(7) div span,
.tbody-row:nth-child(2) td:nth-child(8) div span {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(3) td:nth-child(5) div input,
.tbody-row:nth-child(3) td:nth-child(6) div input,
.tbody-row:nth-child(3) td:nth-child(7) div input,
.tbody-row:nth-child(3) td:nth-child(8) div input {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}
.tbody-row:nth-child(3) td:nth-child(5) div span,
.tbody-row:nth-child(3) td:nth-child(6) div span,
.tbody-row:nth-child(3) td:nth-child(7) div span,
.tbody-row:nth-child(3) td:nth-child(8) div span {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(5) td:nth-child(5) div input,
.tbody-row:nth-child(5) td:nth-child(6) div input,
.tbody-row:nth-child(5) td:nth-child(7) div input,
.tbody-row:nth-child(5) td:nth-child(8) div input {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(5) td:nth-child(5) div span,
.tbody-row:nth-child(5) td:nth-child(6) div span,
.tbody-row:nth-child(5) td:nth-child(7) div span,
.tbody-row:nth-child(5) td:nth-child(8) div span {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(6) td:nth-child(5) div input,
.tbody-row:nth-child(6) td:nth-child(6) div input,
.tbody-row:nth-child(6) td:nth-child(7) div input,
.tbody-row:nth-child(6) td:nth-child(8) div input {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(6) td:nth-child(5) div span,
.tbody-row:nth-child(6) td:nth-child(6) div span,
.tbody-row:nth-child(6) td:nth-child(7) div span,
.tbody-row:nth-child(6) td:nth-child(8) div span {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(8) td:nth-child(5) div input,
.tbody-row:nth-child(8) td:nth-child(6) div input,
.tbody-row:nth-child(8) td:nth-child(7) div input,
.tbody-row:nth-child(8) td:nth-child(8) div input {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(8) td:nth-child(5) div span,
.tbody-row:nth-child(8) td:nth-child(6) div span,
.tbody-row:nth-child(8) td:nth-child(7) div span,
.tbody-row:nth-child(8) td:nth-child(8) div span {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(9) td:nth-child(5) div input,
.tbody-row:nth-child(9) td:nth-child(6) div input,
.tbody-row:nth-child(9) td:nth-child(7) div input,
.tbody-row:nth-child(9) td:nth-child(8) div input {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(9) td:nth-child(5) div span,
.tbody-row:nth-child(9) td:nth-child(6) div span,
.tbody-row:nth-child(9) td:nth-child(7) div span,
.tbody-row:nth-child(9) td:nth-child(8) div span {
  border-bottom: 1px dashed rgb(118 115 115 / 38%);
}

.tbody-row:nth-child(3) td,
.tbody-row:nth-child(6) td {
  padding-bottom: 15px;
}

.button-agency {
  padding: 0;
  outline: none;
  border: none;
  background: none;
  font-weight: 600;
  color: #153b7d;
  width: 115%;
  text-align: inherit;
}

.button-agency:hover {
  color: #3497fd;
  cursor: pointer;
}

.span-plus {
  margin-right: 2.2px;
}

.span-minus {
  margin-left: 2.2px;
}

.span-plus,
.span-minus {
  color: #153b7d;
  font-weight: 600;
  cursor: pointer;
}
@media screen and (min-width: 2700px) {
  .conversions-wrapper,
  .proposal-totals-wrapper {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .table-wrapper {
    width: 65%;
  }
  .projections-container {
    margin: 20px;
    display: flex;
    flex-direction: column;
  }
  .projections-tabs-nav {
    justify-content: center;
  }

  .chart-conversions-ratios,
  .chart-proposal-totals {
    height: 25vh;
    width: 25vw;
  }
}
@media screen and (max-width: 2250px) {
  .conversions-wrapper,
  .proposal-totals-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .projections-tabs-nav {
    width: 30%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  #projections-tabs-nav-tab-conversions {
    margin-left: 0px;
  }

  .tab-content {
    display: flex;
    justify-content: center;
  }

  .table-wrapper {
    width: 100%;
  }

  .table td,
  .table th {
    padding: 0.3rem;
    height: 53px;
  }
  #conversion-table {
    margin: 0 auto;
  }

  .chart-conversions-ratios,
  .chart-proposal-totals {
    margin: 0 auto;
    width: 50vw;
    height: 25vh;
  }
}
@media screen and (max-width: 1500px) {
  .projections-tabs-nav {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media screen and (max-width: 1400px) {
  .projections-tabs-nav {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .table-responsive {
    display: block;
    width: 65%;
    overflow-x: auto;
    margin: auto;
    overflow: auto;
    white-space: nowrap;
  }
  .chart-conversions-ratios,
  .chart-proposal-totals {
    margin: 0 auto;
    width: 67vw;
    height: 15vh;
  }
}
