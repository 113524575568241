.units-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}
.units-list-header {
  color: #153b7d;
  margin-bottom: 1rem;
  font-size: 1.75rem;
}

.unit-link {
  color: #153b7d;
}
.unit-name {
  list-style-type: none;
  padding: 3px;
}
