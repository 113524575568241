.college-proposals-header {
  color: #153b7d;
  font-weight: 600;
  text-align: center;
}

.caret-icon {
  color: #3497fd;
  margin-left: 7px;
}

.negative-number {
  color: red;
}

.college-proposals-container {
  margin: 20px;
}
.college-proposals-table-wrapper {
  width: 70%;
  margin: 0 auto;
  margin-top: 3rem;
}

.college-proposals-table {
  border: 1px solid rgb(118 115 115 / 38%);
  line-height: 1.8;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.college-proposals-table thead {
  color: #153b7d;
  font-weight: 600;
}
