.navbar {
  background: #292e42;
  padding: 20px;
}

#logo {
  color: whitesmoke;
  font-size: 24px;
  margin-right: 65px;
}
#logo:hover {
  color: #3497fd;
}
.navbar-dark .navbar-nav .nav-link {
  color: whitesmoke;
  padding-left: 22px;
  color: whitesmoke;
  font-size: 24px;
  margin-right: 65px;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #3497fd;
}
.universityLogo {
  width: 315px;
}
.college-list {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.college-list:hover {
  color: #3497fd;
}
#selected-college {
  color: #292e42;
  font-weight: bold;
}
.navbar-expand-lg .navbar-nav {
  align-items: baseline;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    align-items: baseline;
    padding-left: -10px;
  }
}
