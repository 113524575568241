.container {
  padding: 20px;
}

.header {
  color: #153b7d;
  font-size: 1.75rem;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.breadcrumbs {
  margin-right: 10px;
}

.cardsContainer {
  margin-top: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.25rem;
    width: 340px;
    align-items: center;
    padding: 1.5rem;

    .cardTitle {
      padding: 1rem;
      font-weight: bold;
    }

    .cardButton {
      color: #fff;
      background-color: #153b7d;
      border-color: #153b7d;
    }

    .icon {
      width: 300px;
      font-size: 10rem;
      color: #153b7d;
    }
  }
  .card-body {
    padding: 1.25rem;
    margin-left: 13px;
    color: #153b7d;
    text-align: center;
  }
}
