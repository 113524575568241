.colleges-view {
  display: flex;
  justify-content: center;
  width: 98%;
  margin: 0 auto;
}

#news-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  justify-content: center;
  margin-top: 6rem;
}
@media screen and (min-width: 2600px) {
  .colleges-view {
    width: 75%;
  }
}

@media screen and (min-width: 2250px) {
  #news-container {
    max-width: 82%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1257px) {
  .news-highlights-wrapper,
  .videos-wrapper {
    width: 88%;
  }
}
@media screen and (max-width: 1450px) {
  #news-container {
    flex-direction: column;
    align-items: center;
  }
  .news-highlights-wrapper,
  .videos-wrapper {
    width: 73%;
  }
  .news-highlights-container,
  .upcoming-events-wrapper {
    width: 88%;
  }
  #colleges-cards-container {
    width: 89%;
    margin: 2rem auto;
  }
}
